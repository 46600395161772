import React from "react";
import { LinkPreview } from "./ui/link-preview";

const getStatusStyle = (status) => {
  switch (status) {
    case "Launch":
      return "bg-green-100 text-green-700";
    case "Building":
      return "bg-orange-100 text-orange-700";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

const SaaSList = () => {
  const items = [
    {
      title: "Cybedefend",
      description:
        "CybeDefend is specializing in Application Security Testing using AI to protect your business from cyber threats",
      icon: (
        <img
          src="logo_cybedefend.png"
          alt="Cybedefend"
          className="rounded-full w-7 h-7 mb-2"
        />
      ),
      link: "https://cybedefend.com",
      status: "Building",
    },
    {
      title: "LauncherBlast",
      description:
        "LauncherBlast helps you to manage better your business's social media communication in one platform",
      icon: (
        <img
          src="logo_launcherblast.png"
          alt="LauncherBlast"
          className="rounded-full w-7 h-7 mb-2"
        />
      ),
      link: "https://launcherblast.com",
      status: "Building",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {items.map((item, index) => (
        <a href={item.link} key={index} className="saas-card">
          <div className="saas-card-status">
            <div
              className={`rounded-full px-2 py-1 text-xs font-bold ${getStatusStyle(
                item.status
              )}`}
            >
              {item.status}
            </div>
          </div>
          <div className="saas-card-content">
            <LinkPreview url={item.link} className="font-bold text-black">
              <div className="saas-card-title">
                {item.icon}
                <h2 className="ml-4 text-xl font-bold text-black">
                  {item.title}
                </h2>
              </div>
            </LinkPreview>
            <p className="saas-card-description">{item.description}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default SaaSList;
