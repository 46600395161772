import React from "react";
import { LinkPreview } from "./ui/link-preview";

const getStatusStyle = (status) => {
  switch (status) {
    case "Launch":
      return "bg-green-100 text-green-700";
    case "Building":
      return "bg-orange-100 text-orange-700";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

const WebsiteList = () => {
  const items = [
    {
      title: "Nexus Games",
      description:
        "I developed Nexus-Games.com using Nuxt JS, focusing on design, coding, SEO, and i18n for international expansion",
      icon: (
        <img
          src="nexus.png"
          alt="Nexus-Games"
          className="rounded-full w-7 h-7 mb-2"
        />
      ),
      link: "https://nexus-games.com",
      status: "Launch",
    },
    {
      title: "Fly Serv",
      description:
        "I migrated and optimized fly-serv.com from Vue JS to Nuxt JS for better SEO and added i18n for international reach.",
      icon: (
        <img
          src="flyserv.png"
          alt="Fly-Serv"
          className="rounded-full w-7 h-7 mb-2"
        />
      ),
      link: "https://www.fly-serv.com",
      status: "Launch",
    },
    {
      title: "Scougal Rubber",
      description:
        "I developed the Scougal Rubber website using Next.js, React, and Tailwind, optimized for SEO.",
      icon: (
        <img
          src="scougal.png"
          alt="Scougal-Rubber"
          className="rounded-full w-7 h-7 mb-2"
        />
      ),
      link: "https://lustrous-creponne-885ee3.netlify.app/",
      status: "Building",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {items.map((item, index) => (
        <a href={item.link} key={index} className="saas-card">
          <div className="saas-card-status">
            <div
              className={`rounded-full px-2 py-1 text-xs font-bold ${getStatusStyle(
                item.status
              )}`}
            >
              {item.status}
            </div>
          </div>
          <div className="saas-card-content">
            <LinkPreview url={item.link} className="font-bold text-black">
              <div className="saas-card-title">
                {item.icon}

                <h2 className="ml-4 text-xl font-bold text-black">
                  {item.title}
                </h2>
              </div>
            </LinkPreview>
            <p className="saas-card-description">{item.description}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default WebsiteList;
