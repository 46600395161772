import React from 'react';
import { FaMapMarkerAlt, FaMoneyBillWave, FaLinkedin, FaInstagram, FaProductHunt, FaGithub, FaEnvelope } from 'react-icons/fa';

const Profile = () => {
  return (
    <div className="flex flex-col items-center md:items-start p-8 rounded-lg md:ml-24 z-10">
      <img
        src="logo.png"
        alt="Julien Zammit"
        className="rounded-full w-48 h-48 mb-8"
      />
      <h1 className="text-4xl font-bold mb-4">Julien Zammit</h1>
      <div className="flex items-center mb-4">
        <img
          src="centrale-lille-logo.png"
          alt="Centrale Lille"
          className="w-12 h-12 mr-4"
        />
        <p className="text-gray-700 text-xl">Engineering student in IT at Centrale Lille</p>
      </div>
      <p className="text-gray-700 text-xl mb-4">
        <FaMapMarkerAlt className="inline-block mr-2" />
        Lille
      </p>
      <p className="text-gray-700 text-xl mb-4">
        <FaMoneyBillWave className="inline-block mr-2" />
        $0k-$1k/month
      </p>
      <p className="text-gray-700 text-xl text-center md:text-left mb-8 max-w-sm">
        <FaEnvelope className="inline-block mr-2" />
        <a href="mailto:contact@nanosaasify.com" className="hover:underline">contact@nanosaasify.com</a>
      </p>
      <p className="text-gray-700 text-xl text-center md:text-left mb-8 max-w-sm">
        You can see here all my Nano SaaS projects, agencies, and web development work. I'm a full-stack developer and I love to create new products.
        Hope you find something useful here.
      </p>
      <div className="flex space-x-8">
        <a href="https://www.linkedin.com/in/julienzammit" className="transform transition-transform duration-300 hover:scale-105 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="text-gray-700 w-8 h-8" />
        </a>
        <a href="https://www.instagram.com/julien_zmt" className="transform transition-transform duration-300 hover:scale-105 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="text-gray-700 w-8 h-8" />
        </a>
        <a href="https://www.producthunt.com/@julien_zammit" className="transform transition-transform duration-300 hover:scale-105 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
          <FaProductHunt className="text-gray-700 w-8 h-8" />
        </a>
        <a href="https://github.com/julienzammit" className="transform transition-transform duration-300 hover:scale-105 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
          <FaGithub className="text-gray-700 w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

export default Profile;
